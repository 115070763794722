<template>
  <payment-terms-form
    v-if="paymentTerm.id"
    ref="payment-terms-form"
  />
  <component-not-found
    v-else-if="error"
    title="Payment terms list"
    :button-link="{name: 'settings-payment-terms-list'}"
  />
  <skeleton v-else />
</template>

<script>
import PaymentTermsForm from '@/views/settings/general/payment-terms/components/PaymentTermsForm.vue'
import config from '@/views/settings/fulfillment/warehouses/warehouseConfig'
import Skeleton from '../components/Skeleton.vue'

export default {
  name: 'PaymentTermsUpdate',
  components: { PaymentTermsForm, Skeleton },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    paymentTerm() {
      return this.$store.state[this.MODULE_NAME].paymentTermForm
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['payment-terms-form']) this.$refs['payment-terms-form'].showConfirmationModal(next)
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const paymentTerm = this.mappingFields(Object.keys(this.fields), data, {
        id: data.id,
        label: data.label,
        is_active: data.is_active,
        rules: data.rules,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, paymentTerm)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PAYMENT_TERM_FORM_CLONE`, paymentTerm)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  setup() {
    const MODULE_NAME = 'settings-payment-terms'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
