<template>
  <div>
    <p class="text-left font-weight-bolder font-large-1">
      {{ $t('Payment Term') }}
    </p>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['label']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ fields[field].label }}</span>
          <b-skeleton-img
            height="40px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div
            v-for="field in ['is_active']"
            :key="field"
            style="height: 70px"
        >
          <span class="inputLabel">{{ 'Status' }}</span>
          <b-skeleton-img
              height="40px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
            v-for="field in ['rules']"
            :key="field"
            style="height: 70px; width: 150px;"
        >
          <span class="inputLabel">{{fields[field].label}}</span>
          <b-skeleton-img
              height="40px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BSkeletonImg, BCol,
} from 'bootstrap-vue'
import config from '../paymentTermsConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeletonImg,
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
